<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS) && contact
    "
  >
    <router-link :to="$objectViewRoute(contact)">
      {{ `${contact.firstname && contact.lastname ? contact.firstname+' '+contact.lastname : contact.company_name}` }}{{ contact.firstname ? '-'+`${contact.firstname}` : '' }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "contact",

  components: {},

  mixins: [formMixin],

  props: {
    contact: {
      type: Object,
      default: () => ({}),
      description: "Contact",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    contact(contact) {},
  },
};
</script>
